<template>
  <div
    class="login"
    style="
      background-image: url('https://ymxgw.i-ling.com/web/uploads/20200929/949c6004e2ceb22f5fde1f650befa0fa.png');
    "
  >
    <div class="opacity" flex="cross:center main:center">
      <el-card
        class="box-card"
        shadow="always"
        style="
          background-image: url(https://ymxgw.i-ling.com/web/uploads/20200929/949c6004e2ceb22f5fde1f650befa0fa.png);
        "
      >
        <img class="logo" src="../assets/logo.png" alt="" />
        <el-form
          :model="ruleForm"
          class="login-form"
          ref="ruleForm"
          label-width="0"
          size="small"
          :rules="rules2"
        >
          <div class="form-title">{{ user_type == 1 ? "管理员" : "员工" }}登录</div>
          <el-form-item prop="username">
            <el-input
              @keyup.enter.native="login('ruleForm')"
              placeholder="请输入用户名"
              v-model="ruleForm.username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              @keyup.enter.native="login('ruleForm')"
              type="password"
              placeholder="请输入密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <el-input
              @keyup.enter.native="login('ruleForm')"
              placeholder="验证码"
              style="width: 140px"
              v-model="ruleForm.captcha"
            ></el-input>
            <img :src="pic_captcha_src" class="pic-captcha" @click="loadPicCaptcha" />
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="ruleForm.checked">记住我，以后自动登录</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              class="login-btn"
              :loading="btnLoading"
              round
              type="primary"
              @click="login('ruleForm')"
              >登录
            </el-button>
          </el-form-item>
        </el-form>
        <img class="logo" src="" alt="" />
        <div v-if="user_type == 1" class="register_box">
          <span class="register">忘记密码?</span>
        </div>
      </el-card>

      <div class="foot" :style="{ bottom: 10 }">
        <!--员工-->
        <template v-if="user_type == 2">
          <a :href="roleSetting.copyright_url" target="_blank">{{
            roleSetting.copyright
          }}</a>
        </template>
        <!--管理员-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      btnLoading: false,
      user_type: 1,
      pic_captcha_src: "",
      ruleForm: {
        username: "",
        password: "",
        captcha: "",
        key: "",
      },
      rules2: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入右侧图片上的文字", trigger: "blur" },
        ],
      },
    };
  },

  created() {
    localStorage.removeItem("admin_token");
    this.loadPicCaptcha();
  },
  methods: {
    loadPicCaptcha() {
      this.$http.get("/captcha/api/math").then((res) => {
        this.pic_captcha_src = res.data.img;
        this.ruleForm.key = res.data.key;
      });
    },

    login() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$http.post("/admin/login", this.ruleForm).then((res) => {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            localStorage.setItem("admin_token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("role", JSON.stringify(res.data.user.role));
            this.$router.push({ path: "/" });
          }).catch(()=>{
            this.loadPicCaptcha();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-size: 100% 100%;
}

.login .box-card {
  position: relative;
  border-radius: 15px;
  z-index: 99;
  border: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  width: 825px;
  height: 480px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
}

.logo {
  position: absolute;
  left: 40px;
  top: 40px;
  height: 80px;
}

.username,
password {
  margin-bottom: 20px;
}

.login-btn {
  width: 100%;
  border-radius: 20px;
  height: 38px;
  font-size: 16px;
  background: linear-gradient(to right, #2e9fff, #3e79ff);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.5);
}

.radio-box {
  height: 35px;
  line-height: 35px;
}

.register_box {
  position: absolute;
  right: 15%;
  bottom: 35px;
  width: 150px;
}

.register {
  display: inline-block;
  width: 48%;
  height: 15px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  color: #4291ff;
}

.el-dialog {
  width: 35%;
}

.el-card__body {
  padding: 0;
}

.login-form {
  padding: 50px 45px 30px;
  height: 480px;
  width: 335px;
  float: right;
  background-color: #fff;
}

.form-title {
  font-size: 26px;
  color: #1f4881;
  margin-bottom: 40px;
}

.opacity {
  background-color: rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.el-input .el-input__inner {
  height: 36px;
  border-radius: 18px;
  background-color: #f7f5fb;
  border-color: #f7f5fb;
}

.foot {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.foot a,
.foot a:visited {
  color: #f3f3f3;
}

.footer-text {
  margin-bottom: 10px;
}

.pic-captcha {
  width: 100px;
  height: 36px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
